import React from 'react'
import styled from 'styled-components'
import Dotdotdot from '@foundation-lib/react-dotdotdot'
import ProductList, {
  Image,
  ImageLink,
  Container,
  Plank,
  Details,
  Title,
  Description,
  Link,
} from 'src/features/products/components/ProductList'

const Date = styled.div`
  font-style: italic;
  margin-top: -5px;
  margin-bottom: 7px;
`

export const PostTile = ({
  color,
  img,
  title,
  href,
  description,
  excerpt,
  date,
}) => (
  <Container>
    <ImageLink to={href}>
      <Image src={img} alt={`${title} cover`} />
    </ImageLink>
    <Details>
      <Plank color={color} />
      <Link to={href}>
        <Title>{title}</Title>
      </Link>
      <Date>{date}</Date>
      <Description>
        <Dotdotdot clamp={2}>{description || excerpt}</Dotdotdot>
      </Description>
    </Details>
  </Container>
)

const PostList = ({ items }) => (
  <ProductList title="Blog" ItemComponent={PostTile} items={items} />
)

export default PostList
