import React from 'react'
// import { kebabCase } from 'lodash'
import { Link } from 'gatsby'
import { NarrowContainer } from 'src/features/layout'
import Item from './Item'
import Content from '../../../components/Content'

const FullPost = ({
  content,
  contentComponent,
  // tags,
  title,
  helmet,
  author,
  date,
  img,
  mobileImg,
}) => {
  const PostContent = contentComponent || Content
  return (
    <NarrowContainer>
      <Link to="/blog">&lt; Back to blog</Link>

      <Item
        title={title}
        date={date}
        author={author}
        isFull
        img={img}
        mobileImg={mobileImg}
      >
        {helmet || ''}

        <PostContent content={content} />

        <footer className="entry-meta">This entry was posted on {date}.</footer>

        {/* tags && tags.length ? (
          <div style={{ marginTop: `4rem` }}>
            <h4>Tags</h4>
            <ul className="taglist">
              {tags.map(tag => (
                <li key={`${tag}tag`}>
                  <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null */}
      </Item>
    </NarrowContainer>
  )
}

export default FullPost
