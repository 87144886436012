import React from 'react'
import BlogItem from './Item'
import useBlogItems from '../data/useBlogItems'

const BlogIndex = () => {
  const blogItems = useBlogItems()

  return blogItems.map(({ description, ...other }) => (
    <BlogItem
      {...other}
      dangerouslySetInnerHTML={{
        __html: description,
      }}
    />
  ))
}

export default BlogIndex
