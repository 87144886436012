import { graphql } from 'gatsby'
import authors from './authors'

// eslint-disable-next-line import/prefer-default-export
export const fullPostFragment = graphql`
  fragment FullPostFields on MarkdownRemark {
    id
    html
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      title
      description
      tags
      author
      featuredimage {
        childImageSharp {
          wide: fixed(width: 1024, height: 350, cropFocus: CENTER) {
            ...GatsbyImageSharpFixed
          }
          narrow: fixed(width: 600, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export const parseData = data => {
  const {
    markdownRemark: { frontmatter, html },
  } = data
  const { childImageSharp } = frontmatter.featuredimage

  return {
    ...frontmatter,
    img: childImageSharp?.wide?.src,
    mobileImg: childImageSharp?.narrow?.src,
    content: html,
    author: authors[frontmatter.author],
  }
}
