import React from 'react'
import { StandardPage } from 'src/features/templates'
import SEO from '../../../components/seo'
import FullList from './FullList'

const GatsbyFullListPage = ({ location }) => (
  <StandardPage location={location}>
    <SEO title="All posts" />
    <h1>Blog</h1>
    <FullList />
  </StandardPage>
)

export default GatsbyFullListPage
