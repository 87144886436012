import { useStaticQuery, graphql } from 'gatsby'
import authors from './authors'

const useBlogItems = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              author
              featuredimage {
                childImageSharp {
                  fixed(width: 200, height: 200, cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges

  return posts.map(({ node }) => {
    const { title, author, description, featuredimage, date } = node.frontmatter
    return {
      img: featuredimage?.childImageSharp?.fixed?.src,
      href: node.fields.slug,
      title: title || node.fields.slug,
      author: authors[author],
      date,
      description,
    }
  })
}

export default useBlogItems
