import React from 'react'
import Helmet from 'react-helmet'
import { StandardPage } from 'src/features/templates'
import { HTMLContent } from '../../../components/Content'
import FullPost from './FullPost'
import { parseData } from '../data/postPageQuery'

const GatsbyFullPostPage = ({ data }) => {
  const post = parseData(data)

  return (
    <StandardPage>
      <Helmet titleTemplate="%s | Blog">
        <title>{`${post.title}`}</title>
        <meta name="description" content={`${post.description}`} />
      </Helmet>

      <FullPost {...post} contentComponent={HTMLContent} />
    </StandardPage>
  )
}

export default GatsbyFullPostPage
