import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FaCalendarAlt } from 'react-icons/fa'

import Heading from '../../../components/text/Heading'

const Item = styled.article`
  clear: both;
  margin: 0 0 1.5em;
`

const Header = styled.header`
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  a {
    text-decoration: none;
  }

  ${Heading} {
    flex: 1 1 auto;
  }
`

const Meta = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 370px) {
    flex-direction: column;
    align-items: start;

    & > * + * {
      margin-top: 15px;
    }
  }

  * {
    white-space: nowrap;
  }
`

const MetaItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 0.8888em;
  font-weight: 400;

  &,
  & a {
    color: rgba(73, 73, 73);
  }

  &:last-child {
    margin-right: 0px;
  }
`

const AuthorImg = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-right: 10px;
  border-radius: 100%;
`

const Content = styled.div`
  margin: 0.3em 0 0;
`

const FeaturedImg = styled.img`
  ${({ isFull }) =>
    isFull
      ? `
    width: 100%;
    margin-bottom: 30px;
  `
      : `
    float: left;
    margin: 5px 30px 20px 0px;
  `}

  @media (max-width: 450px) {
    width: 100%;
    float: none;
    display: block;
  }
`

const Text = styled.div`
  p:first-child {
    margin-top: 0px;
  }
`

const MoreLink = styled(Link)`
  text-decoration: none;
`

// const Footer = styled.footer``

// const Tags = styled.div``

const CalendarIcon = styled(FaCalendarAlt)`
  margin-right: 5px;
  margin-top: -1px;
`

const BlogItem = ({ img, title, href, date, author, isFull, ...other }) => (
  <Item>
    <Header>
      <Heading as="h2">
        {isFull ? title : <Link to={href}>{title}</Link>}
      </Heading>{' '}
      <Meta>
        <MetaItem>
          <AuthorImg
            src={
              author?.img ||
              'https://www.ctvalleybrewing.com/wp-content/uploads/2017/04/avatar-placeholder.png'
            }
            alt={title}
          />
          By {author?.name}
        </MetaItem>
        <MetaItem>
          <CalendarIcon />
          <time dateTime={date} itemProp="datePublished">
            {date}
          </time>
        </MetaItem>
      </Meta>
    </Header>
    <Content>
      {img &&
        (() => {
          const imgNode = <FeaturedImg isFull={isFull} src={img} alt={title} />
          return isFull ? imgNode : <Link to={href}>{imgNode}</Link>
        })()}
      <Text>
        <p {...other} />
      </Text>
    </Content>
    {!isFull && <MoreLink to={href}>Continue Reading</MoreLink>}
    {/* <Footer>
      <Tags></Tags>
    </Footer> */}
  </Item>
)

export default BlogItem
