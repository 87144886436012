import styled from 'styled-components'

const Heading = styled.h1`
  clear: both;
  color: var(--heading-color);
  line-height: 1.75;
  margin: 0.5em 0;

  h2& {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
`

export default Heading
